export default Object.freeze({
  LIST_COMPANY_USER: '1001',
  ADD_COMPANY_USER: '1002',
  UPDATE_COMPANY_USER: '1003',
  MANAGE_USER_RIGHTS: '1004',
  RESET_PASSWORD: '1005',

  LEAD_LIST: '2001',
  ADD_LEAD: '2002',

  ASSIGN_SERVICE_LEAD: '2003',
  LEAD_MORE_DETAILS: '3003',

  EDIT_USER: '7009',

  // Super Admin
  ADD_MASTER_ADMIN_USER: '2000',
  EDIT_MASTER_ADMIN_USER: '2001',
  LIST_MASTER_ADMIN_USER: '2002',

  ADMIN_USER_RIGHTS: '7004',
  DELETE_ADMIN_USER: '7010',
  MANAGE_USER_RIGHT: '7016',
  COPY_USER_RIGHTS: '7017',

  ADD_COMPANY: '10000',
  EDIT_COMPANY: '10001',
  LIST_COMPANY: '10002',

})
